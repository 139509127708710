import React from 'react';
import {createRoot} from 'react-dom/client';
import {CssBaseline, ThemeProvider} from '@mui/material';
import App from './App'; // Your main app component
import customTheme from './theme'; // Import the custom theme
import './index.css';

const root = createRoot(document.getElementById('root'));

root.render(
    <React.StrictMode>
        <ThemeProvider theme={customTheme}>
            <CssBaseline/>
            <App/>
        </ThemeProvider>
    </React.StrictMode>
);

// Service Worker registration
if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
    window.addEventListener('load', () => {
        navigator.serviceWorker.register('./service-worker.js') // Registering the Workbox-generated service worker
            .then(registration => {
                console.log('Service Worker registered with scope:', registration.scope);

                // Listen for updates to the service worker.
                registration.onupdatefound = () => {
                    const installingWorker = registration.installing;
                    if (installingWorker) {
                        installingWorker.onstatechange = () => {
                            if (installingWorker.state === 'installed') {
                                if (navigator.serviceWorker.controller) {
                                    // New update available, show notification to user.
                                    console.log('New content is available; please refresh.');
                                    // You could use a custom event or any other method to notify the user.
                                    window.dispatchEvent(new Event('swUpdated'));
                                } else {
                                    // Content is cached for offline use.
                                    console.log('Content is cached for offline use.');
                                }
                            }
                        };
                    }
                };
            })
            .catch(err => {
                console.log('Service Worker registration failed:', err);
            });
    });
}