// Website created by Basil Kanaan, Nabeel Dar, and Ravnit Lotay

import './App.css';
import React, {Suspense} from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import {GoogleOAuthProvider} from '@react-oauth/google';

const Home = React.lazy(() => import("./pages/Home"));
const PrivacyAndTerms = React.lazy(() => import("./pages/PrivacyAndTerms"));
const SignInUp = React.lazy(() => import("./pages/SignInUp"));
const NotFound = React.lazy(() => import("./pages/NotFound"));
const BlogPost = React.lazy(() => import("./components/Blogs/BlogPost"));
const Blogs = React.lazy(() => import("./pages/Blogs"));

function App() {
    return (

        <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID}>
            <div className="App">
                <Router>
                    <Suspense fallback={<div>...</div>}>
                        <Routes>
                            <Route path="/" element={<Home/>}/>
                            <Route path="/privacy-terms" element={<PrivacyAndTerms/>}/>
                            <Route path="/signin" element={<SignInUp route={"signin"}/>}/>
                            <Route path="/signup" element={<SignInUp route={"signup"}/>}/>
                            <Route path="/reset-password" element={<SignInUp route={"reset-password"}/>}/>
                            <Route path="*" element={<NotFound/>}/>
                            <Route path="/blogs" element={<Blogs/>}/>
                            <Route path="/blogs/:id" element={<BlogPost/>}/>
                        </Routes>
                    </Suspense>
                </Router>
            </div>
        </GoogleOAuthProvider>
    );
}

export default App;
