import {createTheme, responsiveFontSizes} from '@mui/material/styles';

// Base theme definition
let theme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1280,
            xl: 1920,
        },
    },
    typography: {
        fontFamily: 'Inter, sans-serif',
        h1: {
            fontSize: '3.5rem',
            fontWeight: '800',
        },
        h2: {
            fontSize: '3rem',
            fontWeight: '800',
        },
        h3: {
            fontSize: '2rem',
            fontWeight: '600',
        },
        h4: {
            fontSize: '1.5rem',
            fontWeight: '600',
        },
        p: {
            fontSize: '0.5rem',
        },
    },
    palette: {
        mode: 'light',
    },
});

// Customizing for xs breakpoint
theme = createTheme(theme, {
    typography: {
        h1: {
            [theme.breakpoints.down('xs')]: {
                fontSize: '2.5rem', // smaller font size for xs screens
            },
        },
        h2: {
            [theme.breakpoints.down('xs')]: {
                fontSize: '2.25rem',
            },
        },
        h3: {
            [theme.breakpoints.down('xs')]: {
                fontSize: '1.5rem',
            },
        },
        h4: {
            [theme.breakpoints.down('xs')]: {
                fontSize: '1.1rem',
            },
        },
        p: {
            [theme.breakpoints.down('xs')]: {
                fontSize: '0.4rem',
            },
        },
    },
});

// Optionally make font sizes responsive
theme = responsiveFontSizes(theme);

export default theme;